<template>
  <v-card class="ma-3">
    <v-toolbar class="mb-1">
      <v-toolbar-title>Music Juries</v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar>
    <v-expansion-panels v-model="selectedTerm">
      <v-expansion-panel v-for="{ term, label, juries, active, open, openDate } in terms" :key="'panel-' + label">
        <v-expansion-panel-header>{{ label }}</v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-card-text>
            <v-list>
              <v-list-item v-for="{ _id, start, instrument, pieces, eval: evaluations } in juries" :key="_id">
                <v-list-item-content>
                  <v-list-item-title>Jury reserved for {{ instrument }} on {{ stringFormatDate(start) }}</v-list-item-title>
                  <v-list-item-subtitle v-if="pieces && pieces.length > 0">
                    <v-chip v-for="({ name, composer }, index) in pieces" :key="_id + '-' + index">{{ name }} - {{ composer }}</v-chip>
                  </v-list-item-subtitle>
                  <v-list-item-subtitle v-if="evaluations.length === 0">
                    <v-btn outlined @click="editRepertoire(_id, pieces)">
                      <v-icon left>fal fa-pencil</v-icon>
                      Edit Repertoire
                    </v-btn>
                    <v-btn color="error" outlined style="margin-left:1em" @click="cancelJury(_id)">
                      <v-icon left>fal fa-times-circle</v-icon>
                      Cancel Jury
                    </v-btn>
                  </v-list-item-subtitle>
                  <v-list-item-subtitle v-else>
                    <v-btn outlined :to="'/student/music/jury/' + _id">
                      <v-icon left>fal fa-eye</v-icon>
                      View Evaluations
                    </v-btn>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
            <select-jury-dialog v-if="active && open" :has-jury="juries.length > 0" :term="term" @reserved="({ event }) => juries.push(event)"></select-jury-dialog>
            <v-alert v-else-if="active" type="info">Jury selection is not yet open. It opens {{ openDate }}.</v-alert>
            <v-dialog v-model="repertoireDialog" persistent width="600">
              <v-card>
                <v-card-title>Enter the repertoire for your jury</v-card-title>
                <v-expansion-panels>
                  <v-expansion-panel v-for="(piece, index) in repertoirePieces" :key="'repertoire-' + index">
                    <v-expansion-panel-header>Piece #{{ index + 1 }}{{ piece.name !== '' ? ' - ' + piece.name : '' }}</v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-card-text>
                        <v-text-field v-model="repertoirePieces[index].name" :key="'name-' + index" label="Name" outlined></v-text-field>
                        <v-text-field v-model="repertoirePieces[index].composer" :key="'composer-' + index" label="Composer" outlined hide-details></v-text-field>
                      </v-card-text>
                      <v-card-actions>
                        <v-btn v-if="repertoirePieces.length > 3" text color="error" @click="repertoirePieces.splice(index, 1)">Remove Piece</v-btn>
                      </v-card-actions>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
                <v-card-text style="margin-top:1em">
                  <v-btn outlined @click="repertoirePieces.push({ name: '', composer: '' })">Add Another Piece</v-btn>
                </v-card-text>
                <v-card-actions>
                  <v-btn :disabled="!repertoireValid" text color="success" @click="saveRepertoire()">Save</v-btn>
                  <v-spacer></v-spacer>
                  <v-btn text @click="repertoireDialog = false">Cancel</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-card-text>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-card>
</template>

<script>
import { computed, onMounted, reactive, ref } from '@vue/composition-api'
import { stringFormatDate } from '../../../helpers/formatters'
export default {
  components: {
    SelectJuryDialog: () => import('@/components/student/music/selectJuryDialog')
  },
  setup (props, { root }) {
    const user = computed(() => root.$store.state.user.spoof || root.$store.state.user)
    const terms = ref([])
    const selectedTerm = ref(0)
    const service = root.$feathers.service('student/music/jury-slot')
    const slotDialog = ref(false)

    const dateMenu = ref(null)
    const dateMenuActive = ref(false)
    const selectedDate = ref('')
    const availableDates = reactive({})
    const selectedSlot = ref('')
    const slots = computed(() => {
      if (selectedDate.value !== '' && selectedDate.value in availableDates) {
        return availableDates[selectedDate.value]
      }
    })

    function allowedDates (val) {
      return val in availableDates
    }

    async function loadJuries () {
      terms.value = []
      // const termCodes = []
      // const { data: termList } = await root.$feathers.service('student/music/jury-setup').find()
      // for (const { _id: term } of termList) termCodes.push(term)
      // // Get the current term; we will add this to the query to get this term if it's set up in the jury system
      // const { data: termData } = await root.$feathers.service('system/term').find({ query: { $or: [{ term: { $in: termCodes } }, { type: 'Traditional', term: { $regex: '[\\d]{4}(05|60)' }, start: { $lte: new Date() }, end: { $gte: new Date() } }], $sort: { term: -1 } } })
      // for (const { term, label, start, end } of termData) {
      //   const { data: juries } = await service.find({ query: { term, student: user.value.directoryId || '000000000000000000000000', $sort: { start: 1 } } })
      //   let dtStart = new Date(start)
      //   let dtEnd = new Date(end)
      //   let today = new Date()
      //   let active = (today > dtStart && today < dtEnd)
      //   if (juries.length > 0 || active) {
      //     terms.value.push({ term, label, juries, active })
      //   }
      // }
      const aggregate = [
        { $lookup: {
          from: 'Terms',
          localField: 'term',
          foreignField: 'term',
          as: 'term'
        } },
        { $addFields: {
          term: { $first: '$term' }
        } },
        { $addFields: {
          label: '$term.label',
          term: '$term.term',
          start: '$term.start',
          end: '$term.end'
        } },
        { $lookup: {
          from: 'Student-MusicJurySlot',
          let: { term: '$term' },
          pipeline: [
            { $match: {
              $expr: { $eq: ['$$term', '$term'] },
              student: user.value.directoryId
            } }
          ],
          as: 'juries'
        } },
        { $sort: { 'start': -1 } }
      ]
      const { data } = await root.$feathers.service('student/music/jury-setup').find({ query: { aggregate } })
      console.log(data)
      terms.value = data.filter(({ juries, start, end }) => new Date(start) < new Date() && (new Date(end) > new Date() || juries.length > 0)).map(({ term, label, juries, end, open }) => {
        return { term, label, juries, active: new Date(end) > new Date(), open: new Date(open) < new Date(), openDate: stringFormatDate(open) }
      })
    }

    onMounted(() => {
      loadJuries()
    })

    const repertoireDialog = ref(false)
    const repertoireId = ref('')
    const repertoirePieces = ref([])
    function editRepertoire (_id, pieces) {
      repertoireId.value = _id
      const arr = pieces.map((obj) => { return { ...obj } })
      while (arr.length < 3) {
        arr.push({ name: '', composer: '' })
      }
      repertoirePieces.value = arr
      repertoireDialog.value = true
    }
    async function saveRepertoire () {
      try {
        await service.patch(repertoireId.value, { pieces: repertoirePieces.value })
        root.$store.dispatch('main/snackbar', { active: true, color: 'success', text: 'Repertoire saved', timeout: 6000 })
        repertoireDialog.value = false
        loadJuries()
      } catch (e) {
        root.$store.dispatch('main/snackbar', { active: true, color: 'error', text: 'Error saving repertoire: ' + e, timeout: 8000 })
      }
    }
    const repertoireValid = computed(() => {
      for (const { name, composer } of repertoirePieces.value) {
        if (!name || name === '' || !composer || composer === '') return false
      }
      return true
    })

    async function cancelJury (id) {
      if (confirm('Are you sure you want to cancel this jury session?')) {
        try {
          const rec = await service.get(id)
          delete rec.student
          delete rec.instructor
          delete rec.instrument
          // Check and see if this was a sophomore platform slot that is being removed; if so, we need to modify the time to only be 10 minutes again and add a slot back in for the second 10-minutes
          const start = new Date(rec.start)
          const end = new Date(rec.end)
          const minuteDiff = (end - start) / 60000
          if (minuteDiff > 10) {
            start.setMinutes(start.getMinutes() + 10)
            rec.end = start
            // Find the slot that was here and reactivate it
            const { data } = await service.find({ query: { start } })
            if (data.length > 0) {
              await service.patch(data[0]._id, { active: true })
            }
          }
          await service.update(id, rec)
          loadJuries()
        } catch (e) {
          root.$store.dispatch('main/snackbar', { active: true, color: 'error', text: 'Error removing jury: ' + e, timeout: 8000 })
        }
      }
    }

    return {
      terms,
      selectedTerm,
      slotDialog,
      dateMenu,
      dateMenuActive,
      selectedDate,
      availableDates,
      slots,
      selectedSlot,
      allowedDates,
      repertoireDialog,
      repertoireId,
      repertoirePieces,
      editRepertoire,
      saveRepertoire,
      repertoireValid,
      cancelJury,
      stringFormatDate
    }
  }
}
</script>
